<template>
	<transition name="fade" mode="out-in">
		<div v-if="isLoading" class="min-height-screen h-full d-flex align-center justify-center">
			<loader />
		</div>
		<div v-else>
			<!-- error snackbar -->
			<v-snackbar top v-model="hasDeletingAccountError" color="error">
				Oops!! something went wrong try again after some time
				<template v-slot:action="{ attrs }">
					<v-btn color="white" text v-bind="attrs" @click="hasDeletingAccountError = false">
						Close
					</v-btn>
				</template>
			</v-snackbar>

			<!-- delete account modal -->
			<modal v-model="isSureToDeleteAccount" @close="confirmText = ''">
				<template #body>
					<div class="d-flex flex-column px-5 pb-5 mx-auto">
						<p class="text-theme text-lg mb-2 font-weight-bold">
							Are you sure want to delete your account?
						</p>
						<p class="text--secondary text-sm font-italic mb-2">
							<strong>Note:</strong> Your Goals, Order details will also get deleted.
						</p>
						<v-form ref="confirmFormRef" class="my-2" @submit.prevent="handleDeleteAccount">
							<label for="confirm" class="text-base text-theme font-weight-bold d-inline-block"
								>Enter <span class="text-uppercase primary--text">Confirm</span> to delete
								account</label
							>
							<v-text-field
								id="confirm"
								outlined
								hide-details
								class="rounded-small my-3"
								:value="formattedUppercaseConfirmText"
								autocomplte="off"
								@input="
									(val) => {
										if (val) {
											confirmText = val.toLowerCase()
										}
									}
								"
								placeholder="CONFIRM"
							/>

							<v-btn
								type="submit"
								color="primary"
								class="ma-2 rounded-small text-capitalize font-weight-bold"
								:disabled="!isConfirmed"
								:loading="isDeletingUserAccount"
								>Confirm</v-btn
							>
						</v-form>
					</div>
				</template>
			</modal>

			<v-card flat elevation="0" class="min-height-screen h-full">
				<v-toolbar flat class="border-b-2 border--secondary py-3 !h-85px">
					<v-toolbar-title><strong> My Profile </strong></v-toolbar-title>
				</v-toolbar>

				<div>
					<a
						:href="`${baseUrl}/me/edit?token=${authToken}`"
						class="d-flex align-center pa-4 text-decoration-none"
					>
						<div class="flex-grow-1">
							<h1 class="text-base sm:text-lg text-theme text-capitalize">{{ userFullName }}</h1>
							<span class="text-sm sm:text-base text--secondary">{{ user.emailId }}</span>
						</div>
						<span class="v-btn v-btn--icon v-btn--round theme--light v-size--x-small">
							<v-icon class="primary--text">mdi-pencil</v-icon>
						</span>
					</a>
					<a
						:href="`${baseUrl}/me/orders?token=${this.authToken}`"
						class="d-flex align-center px-4 py-3 border-b-2 border--secondary text-decoration-none"
					>
						<span class="flex-grow-1 text-decoration-none">
							<span class="text-sm sm:text-base d-block text-theme font-weight-bold"
								>My Orders</span
							>
							<span class="text-xs sm:text-sm text--secondary">Check your orders</span>
						</span>
						<v-btn icon color="primary" x-small>
							<v-icon>mdi-chevron-right</v-icon>
						</v-btn>
					</a>
					<a
						:href="`${baseUrl}/me/password/change?token=${this.authToken}`"
						class="d-flex align-center px-4 py-3 border-b-2 border--secondary text-decoration-none"
					>
						<span class="flex-grow-1 d-block">
							<span class="text-sm sm:text-base d-block text-theme font-weight-bold"
								>Change Password</span
							>
							<span class="text-xs sm:text-sm text--secondary">*********</span>
						</span>
						<v-btn icon color="primary" x-small>
							<v-icon>mdi-chevron-right</v-icon>
						</v-btn>
					</a>

					<!-- delete account btn -->
					<div class="px-2 mt-6">
						<v-btn
							large
							color="red"
							elevation="0"
							class="text-capitalize rounded-small font-weight-bold white--text"
							@click="isSureToDeleteAccount = true"
							>Delete Account</v-btn
						>
					</div>
				</div>
			</v-card>
		</div>
	</transition>
</template>

<script>
import AuthMixin from '@/mixins/auth'
import Loader from '@/components/Loader.vue'
import { getUserProfileDetails, deleteUserAccount } from '@/services/profile'
import { SUCCESS } from '@/constants/status-code'
import { BASE_URL } from '@/constants'

const Modal = () => import('@/components/Modal.vue')

export default {
	name: 'ProfilePage',
	mixins: [AuthMixin],
	components: {
		Loader,
		Modal,
	},
	data() {
		return {
			baseUrl: BASE_URL,
			isLoading: false,
			isSureToDeleteAccount: false,
			isDeletingUserAccount: false,
			hasDeletingAccountError: false,
			confirmText: '',
			user: {},
		}
	},
	created() {
		this.fetchUserDetails()
	},
	computed: {
		userFullName() {
			if (!this.user) return ''
			const { firstName, lastName } = this.user

			if (lastName) {
				return `${firstName} ${lastName}`
			}
			return firstName
		},
		formattedUppercaseConfirmText() {
			return this.confirmText.toUpperCase()
		},
		isConfirmed() {
			return this.confirmText === 'confirm'
		},
	},
	methods: {
		async fetchUserDetails() {
			this.isLoading = true

			try {
				const res = await getUserProfileDetails(this.authToken)
				const { data, statusCode } = res.data

				if (statusCode === SUCCESS) {
					this.user = data
				}
			} catch (e) {
				console.log(e, 'error')
			}
			this.isLoading = false
		},
		async handleDeleteAccount() {
			if (!this.isConfirmed) return

			this.isDeletingUserAccount = true
			try {
				const res = await deleteUserAccount(this.authToken)
				const { statusCode } = res.data

				if (statusCode !== SUCCESS) throw new Error('Error deleting user account')
				this.isDeletingUserAccount = false

				window.location.href = '/account-deleted'
			} catch (e) {
				this.hasDeletingAccountError = true
			}
			this.isDeletingUserAccount = false
			this.isSureToDeleteAccount = false
			this.$refs.confirmFormRef.reset()
		},
	},
}
</script>
